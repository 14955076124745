import { FC } from 'react'
import LogoSvg from '@/public/images/common/PROTOCOL_logo_black.svg'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  isWhite?: boolean
}

export const Logo: FC<Props> = ({ isWhite = false }) => {
  return (
    <span
      className={classNames(styles.container, {
        [styles.white]: isWhite,
      })}
    >
      <LogoSvg />
    </span>
  )
}
